.question-container {
  text-align: left !important;
  margin-top: 2rem;
  color: white;
}

.question-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.description {
  font-size: 1rem;
  margin: 1rem 0;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.option-button {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  border-color: white;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid white;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.option-button:hover {
  background-color: #ddd;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  background-color: #1a6e8b; /* Blue background */
  border: 1px solid white; /* Border color */
  color: white; /* Text color */
  font-size: 1rem; /* Adjust font size as needed */
  border-radius: 50%; /* Makes it a circle */
  text-align: center;
  margin-right: 10px; /* Space between the circle and text */
}

.container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the circle and text */
}
