.App {
  display: flex;
  background-color: #1a6e8b; /* Blue background */
  color: white;
  min-height: 100vh;
  font-family: Arial, sans-serif;

  display: flex;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.left-side {
  flex: 1; /* Takes up 50% of the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Light background for contrast */
}

.right-side {
  flex: 1; /* Takes up 50% of the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a6e8b; /* Match background color for logo section */
}

@media (max-width: 1425px) {
  .App {
    flex-direction: column;
    align-items: center;
  }

  .left-side {
    width: 100%; /* Full width */
  }

  .right-side {
    width: 100%; /* Full width */
  }
}
